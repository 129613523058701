import * as THREE from 'three'

export default class InformationSection
{
    constructor(_options)
    {
        // Options
        this.time = _options.time
        this.resources = _options.resources
        this.objects = _options.objects
        this.areas = _options.areas
        this.tiles = _options.tiles
        this.debug = _options.debug
        this.x = _options.x
        this.y = _options.y

        // Set up
        this.container = new THREE.Object3D()
        this.container.matrixAutoUpdate = false

        this.setStatic()
        this.setLinks()
        this.setActivities()
        this.setTiles()
    }

    setStatic()
    {
        this.objects.add({
            base: this.resources.items.informationStaticBase.scene,
            collision: this.resources.items.informationStaticCollision.scene,
            floorShadowTexture: this.resources.items.informationStaticFloorShadowTexture,
            offset: new THREE.Vector3(this.x, this.y, 0),
            mass: 0
        })
    }

    // setBaguettes()
    // {
    //     this.baguettes = {}

    //     this.baguettes.x = - 4
    //     this.baguettes.y = 6

    //     this.baguettes.a = this.objects.add({
    //         base: this.resources.items.informationBaguetteBase.scene,
    //         collision: this.resources.items.informationBaguetteCollision.scene,
    //         offset: new THREE.Vector3(this.x + this.baguettes.x - 0.56, this.y + this.baguettes.y - 0.666, 0.2),
    //         rotation: new THREE.Euler(0, 0, - Math.PI * 37 / 180),
    //         duplicated: true,
    //         shadow: { sizeX: 0.6, sizeY: 3.5, offsetZ: - 0.15, alpha: 0.35 },
    //         mass: 1.5,
    //         // soundName: 'woodHit'
    //     })

    //     this.baguettes.b = this.objects.add({
    //         base: this.resources.items.informationBaguetteBase.scene,
    //         collision: this.resources.items.informationBaguetteCollision.scene,
    //         offset: new THREE.Vector3(this.x + this.baguettes.x - 0.8, this.y + this.baguettes.y - 2, 0.5),
    //         rotation: new THREE.Euler(0, - 0.5, Math.PI * 60 / 180),
    //         duplicated: true,
    //         shadow: { sizeX: 0.6, sizeY: 3.5, offsetZ: - 0.15, alpha: 0.35 },
    //         mass: 1.5,
    //         sleep: false,
    //         // soundName: 'woodHit'
    //     })
    // }

    setLinks()
    {
        // Set up
        this.links = {}
        this.links.x = 6.8
        this.links.y = - 1.5
        this.links.halfExtents = {}
        this.links.halfExtents.x = 1
        this.links.halfExtents.y = 1
        this.links.distanceBetween = 2.4
        this.links.labelWidth = this.links.halfExtents.x * 2 + 1
        this.links.labelGeometry = new THREE.PlaneBufferGeometry(this.links.labelWidth, this.links.labelWidth * 0.25, 1, 1)
        this.links.labelOffset = - 1.6
        this.links.items = []

        this.links.container = new THREE.Object3D()
        this.links.container.matrixAutoUpdate = false
        this.container.add(this.links.container)

        // Options
        this.links.options = [
            {
                href: 'https://www.linkedin.com/in/graysonfleming',
                labelTexture: this.resources.items.informationContactLinkedinLabelTexture
            },
            {
                href: 'mailto:gr@ysonflemi.ng',
                labelTexture: this.resources.items.informationContactMailLabelTexture
            }
        ]

        // Create each link
        let i = 0
        for(const _option of this.links.options)
        {
            // Set up
            const item = {}
            item.x = this.x + this.links.x + this.links.distanceBetween * i
            item.y = this.y + this.links.y
            item.href = _option.href

            // Create area
            item.area = this.areas.add({
                position: new THREE.Vector2(item.x, item.y),
                halfExtents: new THREE.Vector2(this.links.halfExtents.x, this.links.halfExtents.y)
            })
            item.area.on('interact', () =>
            {
                window.open(_option.href, '_blank')
            })

            // Texture
            item.texture = _option.labelTexture
            item.texture.magFilter = THREE.NearestFilter
            item.texture.minFilter = THREE.LinearFilter

            // Create label
            item.labelMesh = new THREE.Mesh(this.links.labelGeometry, new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: _option.labelTexture, depthTest: true, depthWrite: false, transparent: true }))
            item.labelMesh.position.x = item.x + this.links.labelWidth * 0.5 - this.links.halfExtents.x
            item.labelMesh.position.y = item.y + this.links.labelOffset
            item.labelMesh.matrixAutoUpdate = false
            item.labelMesh.updateMatrix()
            this.links.container.add(item.labelMesh)

            // Save
            this.links.items.push(item)

            i++
        }
    }

    setActivities()
    {        
        // Set up
        this.activities = {}
        this.activities.x = this.x + 18
        this.activities.y = this.y - 12
        this.activities.multiplier = 10
        this.activities.halfExtents = {}
        this.activities.halfExtents.x = .5
        this.activities.halfExtents.y = .5
        this.activities.distanceBetween = 1.35
        this.activities.labelWidth = this.activities.halfExtents.x * 2 + 1
        this.activities.labelGeometry = new THREE.PlaneBufferGeometry(this.activities.labelWidth*2, this.activities.labelWidth * 0.25, 1, 1)
        this.activities.labelOffset = - 1.6
        this.activities.items = []

        this.activities.container = new THREE.Object3D()
        this.activities.container.matrixAutoUpdate = false
        this.container.add(this.activities.container)

        this.activities.options = [

            {
                href: 'https://tucson.com/sports/arizonawildcats/grayson-fleming-wildcats-throwers-prepare-to-launch-at-tucson-elite/article_c9f57f7e-ed22-538d-a21e-5bce58540e24.html',
                labelTexture: this.resources.items.informationContactMailLabelTexture
            },
            {
                href: 'https://research.arizona.edu/stories/hammer-throw-physics',
                labelTexture: this.resources.items.informationContactLinkedinLabelTexture
            },            
            {
                href: 'https://arizonawildcats.com/sports/2018/3/22/the-catsys-award-show.aspx',
                labelTexture: this.resources.items.informationContactGithubLabelTexture
            },
            {
                href: 'https://arizonawildcats.com/news/2019/3/21/in-the-circle-with-grayson-fleming.aspx',
                labelTexture: this.resources.items.informationContactTwitterLabelTexture
            },
            {
                href: 'https://patents.google.com/patent/US20210409845',
                labelTexture: this.resources.items.informationContactGithubLabelTexture,
                patent: true
            },
            {
                href: 'https://patents.google.com/patent/US20220129510A1',
                labelTexture: this.resources.items.informationContactLinkedinLabelTexture,
                patent: true
            },
            {
                href: 'https://patents.google.com/patent/US20220083974',
                labelTexture: this.resources.items.informationContactMailLabelTexture,
                patent: true
            },

        ]

        let i = 0
        for(const _option of this.activities.options)
        {
            // Set up
            const item = {}
            item.x = _option.hasOwnProperty('patent') ?  this.activities.x + 1 : this.activities.x - 10
            item.y = _option.hasOwnProperty('patent') ?  this.activities.y + this.activities.distanceBetween * i * 1.5 - 10.3: this.activities.y + this.activities.distanceBetween * i - 1.9
            item.z = _option.hasOwnProperty('patent') ?  10 : 5
            item.href = _option.href

            // Create area
            item.area = this.areas.add({
                position: new THREE.Vector3(item.x, item.y, item.z),
                halfExtents: new THREE.Vector2(this.activities.halfExtents.x, this.activities.halfExtents.y)
            })
            item.area.on('interact', () =>
            {
                window.open(_option.href, '_blank')
            })

            // // Texture
            // item.texture = _option.labelTexture
            // item.texture.magFilter = THREE.NearestFilter
            // item.texture.minFilter = THREE.LinearFilter

            // // Create label
            // item.labelMesh = new THREE.Mesh(this.activities.labelGeometry, new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: _option.labelTexture, depthTest: true, depthWrite: false, transparent: true }))
            // item.labelMesh.position.x = item.x + this.activities.labelWidth * 0.5 - this.activities.halfExtents.x
            // item.labelMesh.position.y = item.y + this.activities.labelOffset
            // item.labelMesh.matrixAutoUpdate = false
            // item.labelMesh.updateMatrix()
            // this.activities.container.add(item.labelMesh)

            // Save
            this.activities.items.push(item)

            i++
        }


        // Geometry
        this.activities.geometry = new THREE.PlaneBufferGeometry(2 * this.activities.multiplier, 1 * this.activities.multiplier, 1, 1)

        // Texture
        this.activities.texture = this.resources.items.informationActivitiesTexture
        this.activities.texture.magFilter = THREE.NearestFilter
        this.activities.texture.minFilter = THREE.LinearFilter

        // Material
        this.activities.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.activities.texture, transparent: true })

        // Mesh
        this.activities.mesh = new THREE.Mesh(this.activities.geometry, this.activities.material)
        this.activities.mesh.position.x = this.activities.x
        this.activities.mesh.position.y = this.activities.y
        this.activities.mesh.position.z = -.01
        this.activities.mesh.matrixAutoUpdate = false
        this.activities.mesh.updateMatrix()
        this.container.add(this.activities.mesh)
    }

    setPatents()
    {     
        // Set up
        this.patents = {}
        this.patents.x = this.x + 29
        this.patents.y = this.y - 12
        this.patents.multiplier = 10
        this.patents.halfExtents = {}
        this.patents.halfExtents.x = .5
        this.patents.halfExtents.y = .5
        this.patents.distanceBetween = 1.9
        this.patents.labelWidth = this.patents.halfExtents.x * 2 + 1
        this.patents.labelGeometry = new THREE.PlaneBufferGeometry(this.patents.labelWidth, this.patents.labelWidth * 0.25, 1, 1)
        this.patents.labelOffset = - 1.2
        this.patents.items = []

        this.patents.container = new THREE.Object3D()
        this.patents.container.matrixAutoUpdate = false
        this.container.add(this.patents.container)

        this.patents.options = [

            {
                href: 'https://pdfaiw.uspto.gov/.aiw?PageNum=0&docid=20220083974&IDKey=89E53F0F888B',
                labelTexture: this.resources.items.informationContactMailLabelTexture
            },
            {
                href: 'hhttps://pdfaiw.uspto.gov/.aiw?PageNum=0&docid=20220129510&IDKey=3047318A3ADD',
                labelTexture: this.resources.items.informationContactLinkedinLabelTexture
            },            
            {
                href: 'https://pdfaiw.uspto.gov/.aiw?Docid=20210409845',
                labelTexture: this.resources.items.informationContactGithubLabelTexture
            }    
        ]

        let i = 0
        for(const _option of this.patents.options)
        {
            // Set up
            const item = {}
            item.x = this.patents.x - 10
            item.y = this.patents.y + this.patents.distanceBetween * i - 1.9
            item.href = _option.href

            // Create area
            item.area = this.areas.add({
                position: new THREE.Vector2(item.x, item.y),
                halfExtents: new THREE.Vector2(this.patents.halfExtents.x, this.patents.halfExtents.y)
            })
            item.area.on('interact', () =>
            {
                window.open(_option.href, '_blank')
            })

            // Save
            this.patents.items.push(item)
            i++
        }
    }

    setTiles()
    {
        this.tiles.add({
            start: new THREE.Vector2(this.x - 1.2, this.y + 13),
            delta: new THREE.Vector2(0, - 20)
        })
    }
}
